.player-container{
    position: absolute;
    top: 0;
    left: 0;
    padding: 2rem;
}

.toggle-button{
    color: rgb(246, 0, 0);
    background-color: rgb(0, 0, 0);
    font-family: 'Press Start 2P', cursive;
    padding: 1rem;

}