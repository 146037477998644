@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

.logo-container{
    display: flex;
    align-items: center;
    flex-direction: column;
    
}

.header-container{
    max-width: 768px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
}

.description{
    margin-top: 1rem;
    font-size: 1rem;
    line-height: 2rem;
    color: white;
}

.header {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}