.background-container{
    min-height: 100vh;
    
}

.footer-background {
    width: 100vw;
    height: 175px;
    display: flex;
    bottom: 0;
    position: absolute;
}

.content-container{
    z-index: 10;
    position: relative;
}