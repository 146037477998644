

/* Margin Bottom */
.mt-1{
    margin-bottom: .5rem;
}
.mb-2{
    margin-bottom: 1rem;
}
.mb-3{
    margin-bottom: 2rem;
}
.mb-4{
    margin-bottom: 4rem;
}
.mb-5{
    margin-bottom: 8rem;
}

/* Margin Top */
.mt-1{
    margin-top: .5rem;
}
.mt-2{
    margin-top: 1rem;
}
.mt-3{
    margin-top: 2rem;
}
.mt-4{
    margin-top: 4rem;
}
.mt-5{
    margin-top: 8rem;
}