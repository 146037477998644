@import url(https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap);
body,
html {
  margin: 0;
}

body {
  padding: 0;
  font-family: 'Press Start 2P',-apple-system, Inter, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  height: 100vh;
  background-color: #0d1116;
  overflow: scroll;
  text-align: center;
}

.container {
  height: 100%;
  background-color: #0d1116;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.header-container {
  padding-top: 30px;
}

.header {
  margin: 0;
  font-size: 50px;
  font-weight: bold;
}

.sub-text {
  font-size: 25px;
  color: white;
}

.gradient-text {
  background: -webkit-linear-gradient(left, #60c657 30%, #35aee2 60%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.cta-button {
  height: 45px;
  border: 0;
  width: auto;
  padding-left: 40px;
  padding-right: 40px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  color: white;
}

.connect-wallet-button {
  background: -webkit-linear-gradient(left, #60c657, #35aee2);
  background-size: 200% 200%;
  -webkit-animation: gradient-animation 4s ease infinite;
          animation: gradient-animation 4s ease infinite;
}

.mint-button {
  background: -webkit-linear-gradient(left, #a200d6, #ff6fdf);
  background-size: 200% 200%;
  -webkit-animation: gradient-animation 4s ease infinite;
          animation: gradient-animation 4s ease infinite;
  margin-right: 15px;
}

.opensea-button {
  background-color: rgb(32, 129, 226);
}

.mint-count {
  color: white;
  font-size: 18px;
  font-weight: bold;
}

.footer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 30px;
}

.twitter-logo {
  width: 35px;
  height: 35px;
}

.footer-text {
  color: white;
  font-size: 16px;
  font-weight: bold;
}

/* KeyFrames */
@-webkit-keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}



/* Margin Bottom */
.mt-1{
    margin-bottom: .5rem;
}
.mb-2{
    margin-bottom: 1rem;
}
.mb-3{
    margin-bottom: 2rem;
}
.mb-4{
    margin-bottom: 4rem;
}
.mb-5{
    margin-bottom: 8rem;
}

/* Margin Top */
.mt-1{
    margin-top: .5rem;
}
.mt-2{
    margin-top: 1rem;
}
.mt-3{
    margin-top: 2rem;
}
.mt-4{
    margin-top: 4rem;
}
.mt-5{
    margin-top: 8rem;
}
.logo-container{
    display: flex;
    align-items: center;
    flex-direction: column;
    
}

.header-container{
    max-width: 768px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
}

.description{
    margin-top: 1rem;
    font-size: 1rem;
    line-height: 2rem;
    color: white;
}

.header {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}
.status-text {
  color: lightskyblue;
  font-size: 14px;
  word-wrap: break-word;
}

.status-text.button {
  color: red;
  cursor: pointer;
  text-decoration: underline;
  margin-left: 0.5rem;
}

.status-text.big {
    color: white;
    font-size: 20px;
  }

  .status-text.small {
    color: white;
    font-size: 12px;
  }

.check-mints-container {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.nft-title {
  color: white;
  font-size: 1.5rem;
}

.mint-container {
  padding-right: 1rem;
  padding-left: 1rem;
}

.mint-image {
  width: 450px;
}

.wallet-button {
  color: white;
  border: 5px dashed white;
  line-height: 3rem;
  font-size: 20px;
  background-color: black;
  font-family: "Press Start 2P", cursive;
  padding-right: 2rem;
  padding-left: 2rem;
  cursor: pointer;
}

.wallet-button:hover {
  background-color: darkred;
}

.malls-title{
  color: red;
  font-size: 28px;
  margin-top: 0;
}

.malls-description{
  color: white;
  font-size: 24px;
  line-height: 35px;
}

.malls-attributes{
  color: yellow;
  font-size: 16px;
}

.gallery-wrapper {
  max-width: 960px;
	margin: 0 auto;
	display: flex;
}

.col {
  padding: 20px;
  text-align: start;
}

.col-1 { flex-basis: 8.333%; }
.col-2 { flex-basis: 16.666%; }
.col-3 { flex-basis: 25%; }
.col-4 { flex-basis: 33.333%; }
.col-5 { flex-basis: 41.666%; }
.col-6 { flex-basis: 50%; }

.background-container{
    min-height: 100vh;
    
}

.footer-background {
    width: 100vw;
    height: 175px;
    display: flex;
    bottom: 0;
    position: absolute;
}

.content-container{
    z-index: 10;
    position: relative;
}
.player-container{
    position: absolute;
    top: 0;
    left: 0;
    padding: 2rem;
}

.toggle-button{
    color: rgb(246, 0, 0);
    background-color: rgb(0, 0, 0);
    font-family: 'Press Start 2P', cursive;
    padding: 1rem;

}
