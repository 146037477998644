.status-text {
  color: lightskyblue;
  font-size: 14px;
  word-wrap: break-word;
}

.status-text.button {
  color: red;
  cursor: pointer;
  text-decoration: underline;
  margin-left: 0.5rem;
}

.status-text.big {
    color: white;
    font-size: 20px;
  }

  .status-text.small {
    color: white;
    font-size: 12px;
  }

.check-mints-container {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.nft-title {
  color: white;
  font-size: 1.5rem;
}

.mint-container {
  padding-right: 1rem;
  padding-left: 1rem;
}

.mint-image {
  width: 450px;
}

.wallet-button {
  color: white;
  border: 5px dashed white;
  line-height: 3rem;
  font-size: 20px;
  background-color: black;
  font-family: "Press Start 2P", cursive;
  padding-right: 2rem;
  padding-left: 2rem;
  cursor: pointer;
}

.wallet-button:hover {
  background-color: darkred;
}

.malls-title{
  color: red;
  font-size: 28px;
  margin-top: 0;
}

.malls-description{
  color: white;
  font-size: 24px;
  line-height: 35px;
}

.malls-attributes{
  color: yellow;
  font-size: 16px;
}

.gallery-wrapper {
  max-width: 960px;
	margin: 0 auto;
	display: flex;
}

.col {
  padding: 20px;
  text-align: start;
}

.col-1 { flex-basis: 8.333%; }
.col-2 { flex-basis: 16.666%; }
.col-3 { flex-basis: 25%; }
.col-4 { flex-basis: 33.333%; }
.col-5 { flex-basis: 41.666%; }
.col-6 { flex-basis: 50%; }
